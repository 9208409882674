import React, { useEffect, useState } from "react";
import { api } from "../../../services/api";
import { toast } from "react-toastify";
import Select from "react-select";
import Creatable from "react-select/creatable";

export const PolishManpower_Form = () => {
  var [form_data, setforms] = useState([
    {
      key: 0,
      id: 0,
      coil: 0,
      welding: "",
      od: "",
      type_of_rejection: "",
      lining: "",
      str: "",
      stamp: "",
      mark: "",
      finish: "",
      remarks: "",
      helper: "",
      operator: "",
      remarked: "",
    },
  ]);

  var [planninglist, setplanning] = useState([]);
  var [helperlist, sethelper] = useState([]);
  var [operatorlist, setoperator] = useState([]);

  useEffect(() => {
    api({ api: "/storeitem/polishman/" }).then((data) => {
      setplanning(data);
    });
    api({ api: "/storeitem/polishedhelperlist/" }).then((data) => {
      sethelper(data);
    });
    api({ api: "/storeitem/operatorlist/" }).then((data) => {
      setoperator(data);
    });
  }, []);

  function Senddata(e) {
    e.preventDefault();

    // Array to store error messages for each form entry
    const errorMessages = [];

    // Validate each form entry
    form_data.forEach((data) => {
      if (!data.operator || !data.helper) {
        // Collect error messages for missing fields
        errorMessages.push(`Please fill in all fields for entry ${data.key}`);
      }
    });

    // If there are error messages, display them and prevent form submission
    if (errorMessages.length > 0) {
      errorMessages.forEach((errorMessage) => {
        toast(errorMessage, { autoClose: 2000 });
      });
      return;
    }

    // If the form is valid, proceed with the API call
    api({
      api: "/api/tubeshiftdata/",
      method: "post",
      body: { form: form_data, post: 13 },
    })
      .then(() => {
        toast("Success", { autoClose: 2000 });
        window.location.reload();
      })
      .catch(() => {
        toast("Failed", { autoClose: 2000 });
      });
  }

  const statused = [
    { value: "Ok", label: "Ok" },
    { value: "Not Ok", label: "Not Ok" },
  ];

  const stamp = [
    { value: "Ok", label: "Ok" },
    { value: "Not Ok", label: "Not Ok" },
    { value: "Without Stamp", label: "Without Stamp" },
  ];
  const finesh = [
    { value: "Ok", label: "Ok" },
    { value: "Not Ok", label: "Not Ok" },
    { value: "Without Stamp", label: "Without Stamp" },
  ];

  const type = [
    { value: "Hole", label: "Hole" },
    { value: "Wave", label: "Wave" },
    { value: "Deep Welding", label: "Deep Welding" },
    { value: "Open", label: "Open" },
    { value: "No Issue", label: "No Issue" },
  ];

  const marks = [
    { value: "Hole", label: "Hole" },
    { value: "Wave", label: "Wave" },
    { value: "Deep Welding", label: "Deep Welding" },
    { value: "Open", label: "Open" },
    { value: "No Issue", label: "No Issue" },
  ];

  function handleAddForm(key) {
    if (form_data[key].unit === "") {
    } else {
      var form_body = {
        key: form_data[form_data.length - 1].key + 1,
        id: 0,
        coil: 0,
        welding: "",
        od: "",
        type_of_rejection: "",
        lining: "",
        str: "",
        stamp: "",
        mark: "",
        finish: "",
        remarks: "",
        helper: "",
        operator: "",
        remarked: "",
      };
      setforms([...form_data, form_body]);
    }
  }
  console.log("form_data:", form_data);

  function handleRemoveForm(index) {
    setforms(form_data.filter((item) => item.key !== index));
  }

  return (
    <div className="Purchase_form">
      <div
        className="modal fade"
        id="exampleModalToggle1"
        aria-hidden="true"
        aria-labelledby="exampleModalToggleLabel"
        tabIndex="-1">
        <div className="modal-dialog modal-dialog-centered modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalToggleLabel">
                TubeMill Details
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"></button>
            </div>
            <div
              className="modal-body"
              style={{
                alignItems: "center",
                justifyContent: "center",
              }}>
              <div className="main-form">
                {" "}
                {/* inner forms */}
                {form_data.length > 0 &&
                  form_data.map((data) => {
                    return (
                      <div key={data.key}>
                        <form
                          key={data.key}
                          onSubmit={(e) => {
                            e.preventDefault();
                            handleAddForm(data.key);
                          }}>
                          <div
                            className="row"
                            style={{ "--bs-gutter-x": " 0rem" }}>
                            <div
                              className="col-md-1"
                              style={{
                                alignItems: "center",
                                justifyContent: "center",
                              }}>
                              <div className="mt-4">
                                <div
                                  className="btn-group"
                                  role="group"
                                  aria-label="First group">
                                  <label className="btn btn-sm btn-outline-secondary">
                                    {data.key}
                                  </label>
                                  <button
                                    type="submit"
                                    className="btn btn-sm btn-outline-secondary">
                                    <img
                                      src="https://cdn-icons-png.flaticon.com/128/1828/1828819.png"
                                      width="15"
                                      height="15"
                                      alt="+"
                                    />
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-outline-secondary"
                                    onClick={() => handleRemoveForm(data.key)}>
                                    <img
                                      src="https://cdn-icons-png.flaticon.com/128/5974/5974771.png"
                                      width="15"
                                      height="15"
                                      alt="-"
                                    />
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-11">
                              <div className="row">
                                <div className="col-md-4">
                                  <label className="control-label">
                                    Coil No.
                                  </label>
                                  <Select
                                    onChange={(e) => {
                                      form_data[data.key].id = e.value;
                                      form_data[data.key].coil = e.coil;
                                      form_data[data.key].shift = e.shift;
                                      form_data[data.key].grade = e.grade;
                                      form_data[data.key].thickness =
                                        e.thickness;
                                      form_data[data.key].sizes = e.size;
                                      form_data[data.key].mill = e.mill;
                                      setforms([...form_data]);
                                    }}
                                    options={planninglist}
                                    required
                                  />
                                </div>
                                <div className="col-md-2">
                                  <label className="control-label">Shift</label>
                                  <input
                                    type="text"
                                    placeholder="Shift"
                                    className="form-control"
                                    value={form_data[data.key].shift}
                                    onChange={(e) => {
                                      form_data[data.key].shift =
                                        e.target.value;
                                    }}
                                    disabled
                                  />
                                </div>
                                <div className="col-md-2">
                                  <label className="control-label">
                                    Mill No.
                                  </label>
                                  <input
                                    type="text"
                                    placeholder="Coil No."
                                    className="form-control"
                                    value={form_data[data.key].mill}
                                    onChange={(e) => {
                                      form_data[data.key].mill = e.target.value;
                                    }}
                                    disabled
                                  />
                                </div>
                                <div className="col-md-2">
                                  <label className="control-label">Size</label>
                                  <input
                                    type="text"
                                    placeholder="Size"
                                    className="form-control"
                                    value={form_data[data.key].sizes}
                                    onChange={(e) => {
                                      form_data[data.key].sizes =
                                        e.target.value;
                                    }}
                                    disabled
                                  />
                                </div>
                                <div className="col-md-2">
                                  <label className="control-label">Grade</label>
                                  <input
                                    type="text"
                                    placeholder="Grade"
                                    className="form-control"
                                    value={form_data[data.key].grade}
                                    onChange={(e) => {
                                      form_data[data.key].grade =
                                        e.target.value;
                                    }}
                                    disabled
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12 row">
                              <div className="col-md-2">
                                <label className="control-label">
                                  Thickness
                                </label>
                                <input
                                  type="text"
                                  placeholder="Thicknes"
                                  className="form-control"
                                  value={form_data[data.key].thickness}
                                  onChange={(e) => {
                                    form_data[data.key].thickness =
                                      e.target.value;
                                  }}
                                  disabled
                                />
                              </div>
                              <div className="col-md-2">
                                <label className="control-label">Welding</label>
                                <Select
                                  onChange={(e) => {
                                    form_data[data.key].welding = e.label;
                                    setforms([...form_data]);
                                  }}
                                  options={statused}
                                  required
                                />
                              </div>
                              <div className="col-md-2">
                                <label className="control-label">OD</label>
                                <Select
                                  onChange={(e) => {
                                    form_data[data.key].od = e.label;
                                    setforms([...form_data]);
                                  }}
                                  options={statused}
                                  required
                                />
                              </div>
                              <div className="col-md-2">
                                <label className="control-label">
                                  Type of Rejection
                                </label>
                                <Select
                                  onChange={(e) => {
                                    form_data[data.key].type_of_rejection =
                                      e.label;
                                    setforms([...form_data]);
                                  }}
                                  options={type}
                                  required
                                />
                              </div>
                              <div className="col-md-2">
                                <label className="control-label">STR</label>
                                <Select
                                  onChange={(e) => {
                                    form_data[data.key].str = e.label;
                                    setforms([...form_data]);
                                  }}
                                  options={statused}
                                  required
                                />
                              </div>
                              <div className="col-md-2">
                                <label className="control-label">Mark</label>
                                <Select
                                  onChange={(e) => {
                                    form_data[data.key].mark = e.label;
                                    setforms([...form_data]);
                                  }}
                                  options={marks}
                                  required
                                />
                              </div>
                              <div className="col-md-2">
                                <label className="control-label">Stamp</label>
                                <Select
                                  onChange={(e) => {
                                    form_data[data.key].stamp = e.label;
                                    setforms([...form_data]);
                                  }}
                                  options={marks}
                                  required
                                />
                              </div>
                              <div className="col-md-2">
                                <label className="control-label">Finish</label>
                                <Select
                                  onChange={(e) => {
                                    form_data[data.key].finish = e.label;
                                    setforms([...form_data]);
                                  }}
                                  options={finesh}
                                  required
                                />
                              </div>
                              <div className="col-md-2">
                                <label className="control-label">
                                  Operator Name
                                </label>
                                <Select
                                  onChange={(e) => {
                                    form_data[data.key].operator = e.label;
                                    setforms([...form_data]);
                                  }}
                                  options={operatorlist}
                                  required
                                />
                              </div>
                              <div className="col-md-2">
                                <label className="control-label">
                                  Helper Name
                                </label>
                                <Creatable
                                  onChange={(e) => {
                                    form_data[data.key].helper = e
                                      ? e.label
                                      : ""; // Handle null safely
                                    setforms([...form_data]);
                                  }}
                                  options={helperlist}
                                  isClearable
                                  required
                                />
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    );
                  })}
                {/* end inner forms */}
                <br />
                <center>
                  <input
                    id="submit"
                    type="submit"
                    className="btn btn-success border-secondary"
                    onClick={Senddata}
                    value="Submit"
                  />
                </center>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
